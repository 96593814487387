<template>
    <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
    <section class="ecommerce-application">
        <!-- Chat Content -->
        <b-row>
            <b-col cols="12">
                <b-form @submit.prevent="askQuestion">
                    <b-row>
                        <b-col cols="12" md="4">
                            <b-form-group>
                                <b-input name="title" :required="true" :disabled="loading" v-model="title" placeholder="Title" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group class="">
                                <b-input name="code_q" :required="true" :disabled="loading" v-model="message" placeholder="Describe what kind of code you need" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-button type="submit" variant="primary" :disabled="isEmpty(message) || loading">
                                Generate
                            </b-button>
                            <b-img
                                v-if="!isEmpty(response)"
                                :title="'Copy to Clipboard'"
                                @click="copyToClipBoard(`_responsemsg`)"
                                :src="require('@/assets/images/app-icons/icons8-copy-48.png')"
                                width="24"
                                class="ml-1"
                            />
                        </b-col>
                    </b-row>
                </b-form>
            </b-col>
            <b-col cols="12" class="mt-2">
                <small v-if="isEmpty(response)">Your code will appear here.</small>
                <template v-else>
                    <input type="text" :value="response" :id="`_responsemsg`" style="display: inline-block;width:0px;height:0px; opacity: 0" />
                    <pre style="white-space:pre-wrap;max-height: 100vh; overflow:auto">
                        {{ response }}
                    </pre>
                </template>
            </b-col>
            <b-col cols="12" class="mt-3 w-100">
                <good-data-table ref="savedCodesTable" :columns="fields" :no-serial="true"
                        :total-column="'response.responseData.total'" :data-column="'response.responseData.data'"
                        :api-endpoint="'/ai/saved-codes'"
                        @add-new-info="toggelModal = true" :hideExportBtns="true" />
            </b-col>
            <b-textarea id="copyInput" :value="copyInputValue" style="height: 1px;width:1px;border: none; background: transparent;color: transparent;overflow: hidden;resize: none;padding: 0 0 0 0;" readonly />
        </b-row>
    </section>
</template>
  
<script>
import { mapActions } from 'vuex'
import { isEmpty } from '@/utilities'
import { showErrorNotification, showSuccessNotification, showLoader, hideLoader, showDangerNotification } from '@/@core/comp-functions/ui/app'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'

export default {
    name: 'AICode',
    components: {
        GoodDataTable
    },
    props: {

    },

    data: () => ({
        loading: false,
        message: null,
        title: null,
        response: null,
        copyInputValue: null,
    }),

    computed: {
        fields: {
            get() {
                return [
                    {
                        field: 'title',
                        label: 'Title',
                        align: 'center',
                    },
                    {
                        field: 'message',
                        label: 'Query',
                        align: 'center',
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        align: 'center',
                        sortable: false,
                        type: 'dropdown',
                        actions: [
                            {
                                isIcon: true,
                                iconHtml: this.$helpers.getIcons('copy'),
                                text: 'Copy Response Code',
                                action: props => {
                                    this._copyText(props.code)
                                },
                            },
                            {
                                isIcon: true,
                                iconHtml: this.$helpers.getIcons('delete'),
                                text: 'Delete',
                                action: props => {
                                    this.$swal({
                                        title: this.$t('Do you want to delete this query?'),
                                        iconHtml: this.$helpers.swalIcon('alert.png'),
                                        showCancelButton: true,
                                        confirmButtonText: this.$t('Yes'),
                                        cancelButtonText: this.$t('No'),
                                        customClass: {
                                            confirmButton: 'btn btn-success',
                                            cancelButton: 'btn btn-danger ml-1',
                                            icon: 'border-0',
                                        },
                                        buttonsStyling: false,
                                    }).then(result => {
                                        if (result.value) {
                                            this.deleteItem(props)
                                        }
                                    })
                                },
                            },
                        ],
                    },
                ]
            }
        }
    },
    mounted() {
    },
    methods: {
        isEmpty,
        ...mapActions('ai', [
            'sendMessage',
            'deleteSavedCode',
        ]),
        askQuestion(){
            if(!isEmpty(this.message)){
                showLoader()
                this.loading = true
                const payLoad = {
                    title: this.title,
                    question: this.message,
                    for_code: 1,
                }
                this.sendMessage(payLoad).then(r => {
                    const {_statusCode, responseData, message} = r
                    if(_statusCode === 203){
                        this.$swal({
                            title: this.$t(message),
                            iconHtml: this.$helpers.swalIcon('alert.png'),
                            showCancelButton: true,
                            confirmButtonText: this.$t('Upgrade'),
                            cancelButtonText: this.$t('Not now'),
                            customClass: {
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-danger ml-1',
                                icon: 'border-0',
                            },
                            buttonsStyling: false,
                        }).then(result => {
                            if(result?.isConfirmed){
                                this.$router.push({
                                    name: 'user-profile', query: {
                                        tab: 'subscription'
                                    }
                                })
                            }
                        })
                    }else{    
                        if(typeof responseData == 'string'){
                            showDangerNotification(this, r)
                        }else{
                            this.$refs.savedCodesTable.loadItems()
                            this.response = responseData.join('\r\n')
                        }
                    }
                    this.loading = false
                    hideLoader()
                }).catch(e => {
                    this.loading = false
                    hideLoader()
                });
            }
        },
        async _copyText(text){
            this.copyInputValue = text
            setTimeout(() => {
                let copyInput = document.querySelector('#copyInput');
                copyInput.select();
                try {
                    if(document.execCommand('copy')){
                        showSuccessNotification(this, 'Copied to clipboard');
                    }else{
                        showErrorNotification(this, "Failed to copy");
                    }
                } catch (err) {
                    showErrorNotification(this, "Failed to copy");
                }
                window.getSelection().removeAllRanges()
            }, 100);
        },
        copyToClipBoard(copy_input_id) {
            let copyInput = document.querySelector('#'+copy_input_id);
            copyInput.select();
            try {
                if(document.execCommand('copy')){
                    showSuccessNotification(this, 'Copied to clipboard');
                }else{
                    showErrorNotification(this, "Failed to copy");
                }
            } catch (err) {
                showErrorNotification(this, "Failed to copy");
            }
            window.getSelection().removeAllRanges()
        },
        async deleteItem(code){
            this.loading = true
            showLoader()
            this.deleteSavedCode(code.id).then(r => {
                if (r === true) {
                    this.$refs.savedCodesTable.loadItems()
                }else {
                    showErrorNotification(this, r || "Something went wrong!")
                }
                this.loading = false
                hideLoader()
            }).catch(e => {
                this.loading = false
                hideLoader()
            })
        }
    },

}
</script>
<style scoped>
pre{
    padding: 5px 10px;
    background-color: #333;
    color: #fff;
    border: 1px solid #aaa;
    overflow-x: scroll;
    max-width: 100%;
}
</style>